import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0f234386"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wbg pd16"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_EnumValueListView = _resolveComponent("EnumValueListView");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_el_tabs = _resolveComponent("el-tabs");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createVNode(_component_el_tabs, {
    modelValue: $data.activeTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.activeTab = $event)
  }, {
    default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
      label: "KPI渠道",
      name: "0"
    }, {
      default: _withCtx(() => [$data.activeTab == '0' ? (_openBlock(), _createBlock(_component_EnumValueListView, {
        key: 0,
        type: 0
      })) : _createCommentVNode("", true)]),
      _: 1
    }), _createVNode(_component_el_tab_pane, {
      label: "KPI组织级别",
      name: "1"
    }, {
      default: _withCtx(() => [$data.activeTab == '1' ? (_openBlock(), _createBlock(_component_EnumValueListView, {
        key: 0,
        type: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    }), _createVNode(_component_el_tab_pane, {
      label: "KPI策划选题",
      name: "2"
    }, {
      default: _withCtx(() => [$data.activeTab == '2' ? (_openBlock(), _createBlock(_component_EnumValueListView, {
        key: 0,
        type: 2
      })) : _createCommentVNode("", true)]),
      _: 1
    }), _createVNode(_component_el_tab_pane, {
      label: "KPI平台",
      name: "3"
    }, {
      default: _withCtx(() => [$data.activeTab == '3' ? (_openBlock(), _createBlock(_component_EnumValueListView, {
        key: 0,
        type: 3
      })) : _createCommentVNode("", true)]),
      _: 1
    }), _createVNode(_component_el_tab_pane, {
      label: "KPI行政级别",
      name: "4"
    }, {
      default: _withCtx(() => [$data.activeTab == '4' ? (_openBlock(), _createBlock(_component_EnumValueListView, {
        key: 0,
        type: 4
      })) : _createCommentVNode("", true)]),
      _: 1
    }), _createVNode(_component_el_tab_pane, {
      label: "KPI其他设置",
      name: "5"
    }, {
      default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
        "table-id": 28
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"])]);
}