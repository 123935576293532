import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-12efac1a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "canvas",
  style: {
    "width": "100%",
    "height": "300px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1);
}