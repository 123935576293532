import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-794084fc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wbg pd16"
};
const _hoisted_2 = {
  key: 0,
  style: {
    "color": "#E4AD1F"
  }
};
const _hoisted_3 = {
  key: 1,
  style: {
    "color": "#15C265"
  }
};
const _hoisted_4 = {
  key: 2,
  style: {
    "color": "#005FF5"
  }
};
const _hoisted_5 = {
  key: 3
};
const _hoisted_6 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_el_row = _resolveComponent("el-row");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createVNode(_component_el_row, {
    class: "wd100"
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": "10",
      ref: "trainTable",
      "export-btn": "",
      onOnDetailData: $options.onDetailData
    }, {
      state: _withCtx(data => [data.rowData == 'PASS' ? (_openBlock(), _createElementBlock("span", _hoisted_2, "待培训")) : data.rowData == 'PROCESSING' ? (_openBlock(), _createElementBlock("span", _hoisted_3, "培训中")) : data.rowData == 'FINISH' ? (_openBlock(), _createElementBlock("span", _hoisted_4, "已完成")) : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(data.mapping[data.rowData]), 1))]),
      "operates-handle": _withCtx(data => [data.data.state == 'COMMIT' ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        style: {
          "color": "#005FF5",
          "cursor": "pointer"
        },
        onClick: $event => $options.audit(data.data.id)
      }, "审核", 8, _hoisted_6)) : _createCommentVNode("", true)]),
      expectStartTime: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.rowData ? data.rowData.substring(0, 10) : '-'), 1)]),
      confirmStartTime: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.rowData ? data.rowData.substring(0, 10) : '-'), 1)]),
      _: 1
    }, 8, ["onOnDetailData"])]),
    _: 1
  })]), _createVNode(_component_DialogView, {
    "visible-control": $data.auditVisible,
    "data-id": 30,
    "bind-id": $data.auditId,
    onSubmit: $options.submitAudit,
    onClose: _cache[0] || (_cache[0] = $event => $data.auditVisible = false)
  }, null, 8, ["visible-control", "bind-id", "onSubmit"])], 64);
}