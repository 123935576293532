import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4ce419ca"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wbg pd16"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  key: 2
};
const _hoisted_5 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createVNode(_component_el_row, {
    class: "wd100"
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": "14",
      onOnFetchData: $options.onFetchData
    }, {
      platform: _withCtx(data => [data.rowData == 'ALIPAY' ? (_openBlock(), _createElementBlock("span", _hoisted_2, "支付宝")) : _createCommentVNode("", true), data.rowData == 'WECHAT' ? (_openBlock(), _createElementBlock("span", _hoisted_3, "微信")) : _createCommentVNode("", true), data.rowData == 'ZLB' ? (_openBlock(), _createElementBlock("span", _hoisted_4, "浙里办")) : _createCommentVNode("", true)]),
      avatar: _withCtx(data => [_createElementVNode("img", {
        src: data.rowData,
        style: {
          "width": "60px",
          "height": "60px",
          "object-fit": "cover"
        }
      }, null, 8, _hoisted_5)]),
      enable: _withCtx(data => [_createVNode(_component_el_switch, {
        modelValue: $data.switches[data.rowId],
        "onUpdate:modelValue": $event => $data.switches[data.rowId] = $event,
        onChange: val => $options.updateEnableState(data.rowId, val),
        class: "ml-2",
        style: {
          "--el-switch-on-color": "#005FF5",
          "--el-switch-off-color": "lightgray"
        }
      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])]),
      _: 1
    }, 8, ["onOnFetchData"])]),
    _: 1
  })]);
}