import {createRouter, createWebHashHistory} from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from "@/views/HomeView";
import DashboardView from "@/views/dashboard/DashboardView";
import BannerList from "@/views/banner/BannerList";
import HelpInfoSetting from "@/views/system/HelpInfoSetting";
import MobileUserListView from "@/views/user/MobileUserListView";
import PostView from "@/views/PostView";
import PostAudit from "@/views/audit/PostAudit";
import CommentAudit from "@/views/audit/CommentAudit";
import UserAudit from "@/views/audit/UserAudit";
import FeedbackView from "@/views/feedback/FeedbackView";
import StationManageView from "@/views/station/StationManageView";
import VisitTplView from "@/views/station/VisitTplView";
import VisitListView from "@/views/visit/VisitListView";
import VisiteValuateView from "@/views/visit/VisiteValuateView";
import DrillValuateView from "@/views/drill/DrillValuateView";
import DrillListView from "@/views/drill/DrillListView";
import StudyDataView from "@/views/study/StudyDataView";
import StudyRepoView from "@/views/study/StudyRepoView";
import RepoImportView from "@/views/study/RepoImportView";
import QuestionManageView from "@/views/study/QuestionManageView";
import TrainListView from "@/views/train/TrainListView";
import TrainValuationListView from "@/views/train/TrainValuationListView";
import ConsultListView from "@/views/consult/ConsultListView";
import QuestionnaireListView from "@/views/questionnaire/QuestionnaireListView";
import SysUserListView from "@/views/user/SysUserListView";
import UserRoleListView from "@/views/user/UserRoleListView";
import TeachTopicListView from "@/views/system/TeachTopicListView";
import DrillTopicListView from "@/views/system/DrillTopicListView";
import StudyTopicListView from "@/views/system/StudyTopicListView";
import LoginRecordList from "@/views/system/LoginRecordList";
import SmsSendListView from "@/views/sms/SmsSendListView";
import VisitDetailView from "@/views/visit/VisitDetailView";
import QuestiopRepoTypeView from "@/views/system/QuestiopRepoTypeView";
import TrainDetailView from "@/views/train/TrainDetailView";
import DrillDetailView from "@/views/drill/DrillDetailView";
import KpiConfigView from "@/views/kpi/KpiConfigView";
import KpiAssessmentListView from "@/views/kpi/KpiAssessmentListView";
import KpiPropagandistListView from "@/views/kpi/KpiPropagandistListView";
import TeacherListView from "@/views/train/TeacherListView";
import VisitStatisticView from "@/views/visit/VisitStatisticView";
import StationVisitTemplateManageView from "@/views/station/StationVisitTemplateManageView";
import SystemSettingView from "@/views/system/SystemSettingView.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        redirect: "/dashboard",
        children: [
            {
                path: "dashboard",
                component: DashboardView
            },
            {
                path: 'bannerList',
                component: BannerList
            },
            {
                path: 'postList',
                component: PostView
            },
            {
                path: 'helpSetting',
                component: HelpInfoSetting
            },
            {
                path: '/user/mobile',
                component: MobileUserListView
            }, {
                path: '/audit/post',
                component: PostAudit
            }, {
                path: '/audit/comment',
                component: CommentAudit
            }, {
                path: '/audit/id',
                component: UserAudit
            }, {
                path: '/feedback',
                component: FeedbackView
            }, {
                path: '/station',
                component: StationManageView
            }, {
                path: '/station/visit',
                component: VisitTplView
            }, {
                path: '/visit/valuation',
                component: VisiteValuateView
            },  {
                path: '/visit/statistic',
                component: VisitStatisticView
            }, {
                path: '/visit/list',
                component: VisitListView
            }, {
                path: '/drill/valuation',
                component: DrillValuateView
            }, {
                path: '/drill/list',
                component: DrillListView
            }, {
                path: '/study/data',
                component: StudyDataView
            }, {
                path: '/study/repo',
                component: StudyRepoView
            }, {
                path: '/question/manage/:repoId',
                component: QuestionManageView
            }, {
                path: '/study/import/:repoId',
                component: RepoImportView
            }, {
                path: '/study/train/list',
                component: TrainListView
            }, {
                path: '/study/train/teachers',
                component: TeacherListView
            },{
                path: '/study/train/valuation',
                component: TrainValuationListView
            },{
                path: '/consult/list',
                component: ConsultListView
            },{
                path: '/questionnaire/list',
                component: QuestionnaireListView
            },{
                path: '/feedback/list',
                component: FeedbackView
            },{
                path: '/user/sys',
                component: SysUserListView
            },{
                path: '/role/list',
                component: UserRoleListView
            },{
                path: '/setting/topic/teach',
                component: TeachTopicListView
            },{
                path: '/setting/topic/drill',
                component: DrillTopicListView
            },{
                path: '/setting/topic/study',
                component: StudyTopicListView
            },{
                path: '/setting/logs',
                component: LoginRecordList
            },{
                path: '/sms/list',
                component: SmsSendListView
            },{
                path: '/visit/detail/:dateId',
                component: VisitDetailView
            },{
                path: '/setting/question/repo/type',
                component: QuestiopRepoTypeView
            },{
                path: '/train/detail/:dateId',
                component: TrainDetailView
            },{
                path: '/drill/detail/:dateId',
                component: DrillDetailView
            },{
                path: '/kpi/config',
                component: KpiConfigView
            },{
                path: '/kpi/assessment/list',
                component: KpiAssessmentListView
            },{
                path: '/kpi/propagandist/list',
                component: KpiPropagandistListView
            },{
                path: '/station/visit/tpl/setting/:stationId',
                component: StationVisitTemplateManageView
            },{
                path: '/system/setting',
                component: SystemSettingView
            },
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            auth: false
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
