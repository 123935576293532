import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ecb642fa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wbg pd16"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createVNode(_component_el_row, {
    style: {
      "width": "100%",
      "padding": "10px 20px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_button, {
      class: "primaryBtn",
      icon: 'plus',
      onClick: _cache[0] || (_cache[0] = $event => {
        $data.createVisible = true;
      })
    }, {
      default: _withCtx(() => [_createTextVNode("添加类型")]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, {
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": "22",
      "init-query": $data.initQuery,
      onOnFetchData: $options.onFetchData
    }, {
      enable: _withCtx(data => [_createVNode(_component_el_switch, {
        modelValue: $data.switches[data.rowId],
        "onUpdate:modelValue": $event => $data.switches[data.rowId] = $event,
        onChange: val => $options.updateEnableState(data.rowId),
        class: "ml-2",
        style: {
          "--el-switch-on-color": "#005FF5",
          "--el-switch-off-color": "lightgray"
        }
      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])]),
      _: 1
    }, 8, ["init-query", "onOnFetchData"])]),
    _: 1
  })]), _createVNode(_component_DialogView, {
    "visible-control": $data.createVisible,
    "data-id": 20,
    onOnDialogClose: _cache[1] || (_cache[1] = $event => $data.createVisible = false),
    onSubmit: $options.createTopic
  }, null, 8, ["visible-control", "onSubmit"])], 64);
}