import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9b6a0b48"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wbg pd16"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_el_row = _resolveComponent("el-row");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createVNode(_component_el_row, {
    class: "wd100"
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": "13"
    }, {
      userAvatar: _withCtx(data => [_createElementVNode("img", {
        src: data.rowData,
        style: {
          "width": "60px",
          "height": "60px",
          "object-fit": "cover"
        }
      }, null, 8, _hoisted_2)]),
      "operates-handle": _withCtx(data => [_createElementVNode("span", {
        style: {
          "cursor": "pointer",
          "color": "#005FF5"
        },
        onClick: $event => $options.reply(data.data.id)
      }, "回复", 8, _hoisted_3)]),
      _: 1
    })]),
    _: 1
  })]), _createVNode(_component_DialogView, {
    "visible-control": $data.replyVisible,
    "data-id": 29,
    "bind-id": $data.replyId,
    onSubmit: $options.replyMessage,
    onClose: _cache[0] || (_cache[0] = $event => $data.replyVisible = false)
  }, null, 8, ["visible-control", "bind-id", "onSubmit"])], 64);
}