import BreadCrumb from "@/components/BreadCrumb";
export default {
  name: "MobileUserListView",
  components: {
    BreadCrumb
  },
  data() {
    return {
      crumb: [{
        name: '系统日志'
      }]
    };
  }
};