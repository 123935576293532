import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-584cc32c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wbg pd16"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_input = _resolveComponent("el-input");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_el_row = _resolveComponent("el-row");
  const _component_KpiAddDialog = _resolveComponent("KpiAddDialog");
  const _component_DialogCustomView = _resolveComponent("DialogCustomView");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_BreadCrumb, {
    data: $data.crumb
  }, null, 8, ["data"]), _createVNode(_component_el_row, {
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": "24",
      ref: "kpiTable",
      onOnFetchData: $options.onFetchData,
      "table-align": "center",
      onAddMore: _cache[0] || (_cache[0] = $event => $data.addVisible = true),
      "export-btn": "",
      onImport: $options.onImport
    }, {
      "operates-handle": _withCtx(data => [data.data.state == 'INIT' ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createVNode(_component_el_button, {
        type: "success",
        icon: "Check",
        onClick: $event => $options.pass(data.rowId),
        size: "small"
      }, {
        default: _withCtx(() => [_createTextVNode("通过")]),
        _: 2
      }, 1032, ["onClick"]), _createVNode(_component_el_button, {
        type: "warning",
        icon: "Close",
        onClick: $event => $options.reject(data.rowId),
        size: "small"
      }, {
        default: _withCtx(() => [_createTextVNode("驳回")]),
        _: 2
      }, 1032, ["onClick"])], 64)) : _createCommentVNode("", true)]),
      state: _withCtx(data => [_createElementVNode("span", {
        style: _normalizeStyle(data.style[data.rowData])
      }, _toDisplayString(data.mapping[data.rowData]), 5)]),
      title: _withCtx(data => [_createElementVNode("a", {
        href: data.data.link,
        target: "_blank"
      }, _toDisplayString(data.rowData), 9, _hoisted_2)]),
      type: _withCtx(data => [_createTextVNode(_toDisplayString(data.mapping[data.rowData]), 1)]),
      score: _withCtx(data => [data.data.state != 'PASS' ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(data.rowData), 1)) : (_openBlock(), _createBlock(_component_el_input, {
        key: 1,
        modelValue: $data.score[data.rowId],
        "onUpdate:modelValue": $event => $data.score[data.rowId] = $event,
        onChange: $event => $options.updateScore(data.rowId),
        placeholder: "分数",
        clearable: ""
      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]))]),
      channelName: _withCtx(data => {
        var _data$data;
        return [_createElementVNode("span", null, _toDisplayString(data.rowData), 1), (data === null || data === void 0 || (_data$data = data.data) === null || _data$data === void 0 ? void 0 : _data$data.channelId) === 3 ? (_openBlock(), _createElementBlock("span", _hoisted_4, "-" + _toDisplayString(data.data.platformName), 1)) : _createCommentVNode("", true)];
      }),
      _: 1
    }, 8, ["onOnFetchData", "onImport"])]),
    _: 1
  })]), _createVNode(_component_DialogCustomView, {
    title: "新增记录",
    width: "430px",
    onClose: _cache[2] || (_cache[2] = $event => {
      $data.addVisible = false;
    }),
    "visible-control": $data.addVisible
  }, {
    default: _withCtx(() => [$data.addVisible ? (_openBlock(), _createBlock(_component_KpiAddDialog, {
      key: 0,
      onPost: $options.addNewPost,
      ref: "kpiAddDialog",
      onClose: _cache[1] || (_cache[1] = $event => {
        $data.addVisible = false;
      })
    }, null, 8, ["onPost"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["visible-control"]), _createVNode(_component_DialogView, {
    "data-id": "41",
    "visible-control": $data.passVisible,
    onClose: _cache[3] || (_cache[3] = $event => $data.passVisible = false),
    onSubmit: $options.auditPass
  }, null, 8, ["visible-control", "onSubmit"]), _createVNode(_component_DialogView, {
    "data-id": "42",
    "visible-control": $data.rejectVisible,
    onClose: _cache[4] || (_cache[4] = $event => $data.rejectVisible = false),
    onSubmit: $options.auditReject
  }, null, 8, ["visible-control", "onSubmit"])], 64);
}